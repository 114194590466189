<script setup lang="ts">
    import UserList from '@/account/components/UserList.vue';
    import AddCustomerDialog from '@/account/dialogs/AddCustomerDialog.vue';
    import { ref } from 'vue';
    import Button from 'primevue/button';
    import { useI18n } from 'vue-i18n';
    import { isMobile } from '@/util/breakpoints';

    const { t } = useI18n();
    const addCustomerDialogIsVisible = ref(false);

    function hideAddCustomerDialog(): void {
        addCustomerDialogIsVisible.value = false;
    }
</script>
<template>
    <div class="user-page-container">
        <div class="user-headline-container">
            <h2 class="text-2xl-bold-line-height-auto">{{ t('ACCOUNT.COMPANY.MY_USERS') }}</h2>
            <Button
                class="add-customer-button"
                icon="pi pi-user-plus"
                :label="!isMobile ? t('ACCOUNT.ADD_CUSTOMER') : undefined"
                @click="addCustomerDialogIsVisible = true"
            />
        </div>
        <UserList />
        <AddCustomerDialog
            v-if="addCustomerDialogIsVisible"
            @close="addCustomerDialogIsVisible = false"
            @update="hideAddCustomerDialog"
        />
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .user-page-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: main.$spacing-6;
    }

    .user-headline-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: main.$spacing-6;
        flex-wrap: wrap;
    }
</style>
