<script setup lang="ts">
    import type { CompanyDto, CustomerDto } from '@containex/portal-backend-dto';
    import { useI18n } from 'vue-i18n';
    import Dialog from 'primevue/dialog';
    import Button from 'primevue/button';
    import { customerApi } from '@containex/portal-backend-api-client';
    import { httpClient } from '@/common/api/http-client';
    import { HttpStatusCode } from 'axios';
    import { getLogger } from '@containex/logger';
    import { stringValueOfParam } from '@/util/param';
    import { useRoute } from 'vue-router';
    import { useMarketQuery } from '@/composables/market';
    import { computed } from 'vue';

    const props = defineProps<{
        customer: CustomerDto;
        company: CompanyDto | null;
    }>();

    const emits = defineEmits<{
        close: [];
        update: [];
    }>();

    const { t } = useI18n();
    const language = stringValueOfParam(useRoute().params.language);
    const { market } = useMarketQuery();
    const logger = getLogger('InviteCustomerDialog');

    const marketLowercase = computed(() => market.value?.code.toLowerCase());

    async function sendEmailActivateCustomer(): Promise<void> {
        const response = await customerApi.sendCustomerActivationMail(httpClient, {
            companyId: props.company?.externalId ?? '',
            email: props.customer.email,
            language,
            market: marketLowercase.value ?? '',
        });

        if (response.status !== HttpStatusCode.Ok) {
            logger.error('Error happened while sending invite to customer', response);
        }

        emits('update');
    }
</script>

<template>
    <Dialog
        :visible="true"
        modal
        :header="t('ACCOUNT.INVITE_DIALOG.HEADER')"
        :style="{ width: '40rem', 'max-width': '100%' }"
        @update:visible="emits('close')"
    >
        <div class="content">
            <p>
                {{ t('ACCOUNT.INVITE_DIALOG.INVITE_DETAIL_CONFIRMATION', { email: props.customer.email }) }}
            </p>
            <div class="actions">
                <Button
                    :label="t('ACCOUNT.INVITE_DIALOG.CANCEL')"
                    type="button"
                    outlined
                    autofocus
                    @click="emits('close')"
                />
                <Button :label="t('ACCOUNT.INVITE_DIALOG.INVITE')" type="button" @click="sendEmailActivateCustomer" />
            </div>
        </div>
    </Dialog>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .actions {
        display: flex;
        justify-content: end;
        gap: 1ch;
    }

    .content {
        padding-bottom: main.$spacing-6;
    }
</style>
